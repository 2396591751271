import React from "react";
import styles from './app.module.scss';

function App() {
    const preloadState: {
        companies: { customerNumber: string, name: string, selected: boolean }[],
        vatInformation: string | null;
        currentLanguage: string;
    } = (window as any).app.preloadState.customerPicker;

    const toggleCustomerSelectionDropdown = () => {
        const element = document.getElementById("customer-picker__dropdown")!;
        const isHidden = element.toggleAttribute('hidden');
        if (!isHidden) {
            setTimeout(() => {
                document.addEventListener('click', () => element.setAttribute('hidden', "true"), { once: true });
            });
        }
    };

    const selectCompany = (customerNumber: string) => {
        fetch(`/api/user/selected-company?customerNumber=${customerNumber}`, {
            method: 'PUT',
            headers: {
                "Swecon-Current-Language": preloadState.currentLanguage
            }
        }).then(() => location.reload());
    };

    const selectedCompany = preloadState.companies.find(company => company.selected);

    return (
        <div className={styles.customerPickerEndContainer}>
            <span>{preloadState.vatInformation}</span>
            {selectedCompany &&
                <a className={styles.customerPickerSelect} onClick={toggleCustomerSelectionDropdown}>
                    <span className={styles.customerPickerSelectName}>{selectedCompany.name}</span>
                    <span>({selectedCompany.customerNumber})</span>
                </a>
            }
            {preloadState.companies.length > 0 &&
                <ul id="customer-picker__dropdown" className="customer-picker__dropdown" hidden>
                    {preloadState.companies.map(company => 
                    <li onClick={() => selectCompany(company.customerNumber)} key={company.customerNumber} className={`customer-picker__dropdown-item ${company.selected ? " active" : ""}`}>
                        <span>{company.name}</span>
                        <span>{company.customerNumber}</span>
                    </li>
                    )}
                </ul>
            }
        </div>
    )
}

export default App
